<mat-card>
   <mat-card-title>Privacy Policy</mat-card-title>
   <mat-card-content>
      <p>
         Last updated: 17 February 2020
      </p>
      <h3>
         The Gist
      </h3>
      <p>
         We collect two types of information:
      </p>
      <ol>
         <li>
            Information you explicitly provide to us, such as your email address or data you enter
            into our application(s).
         </li>
         <li>
            Information that we collect based on your usage of our website(s) or application(s),
            such as how long you spend on a particular webpage or whether you use a particular
            feature of an application.
         </li>
      </ol>
      <p>
         We collect information for the sole purpose of improving your and others experiences using
         our website(s) and application(s). We do not and will not share or sell your information to
         any third party.
      </p>
      <p>
         This privacy notice discloses the privacy practices for Aligned Applications and our
         websites; https://alignedapps.com, https://trellistools.com and https://opsmanager.io. This
         privacy notice applies solely to information collected by this website, except where stated
         otherwise. It will notify you of the following:
      </p>
      <ul>
         <li>
            What information we collect;
         </li>
         <li>
            With whom it is shared;
         </li>
         <li>
            How it can be corrected;
         </li>
         <li>
            How it is secured;
         </li>
         <li>
            How policy changes will be communicated; and
         </li>
         <li>
            How to address concerns over misuse of personal data.
         </li>
      </ul>
      <h3>
         Information Collection, Use, and Sharing
      </h3>
      <p>
         We are the sole owners of the information collected on this site. We only have access
         to/collect information that you voluntarily give us via email or other direct contact from
         you. We will not sell or rent this information to anyone.
      </p>
      <p>
         We will use your information to respond to you, regarding the reason you contacted us. We
         will not share your information with any third party outside of our organization, other
         than as necessary to fulfill your request, e.g., to ship an order.
      </p>
      <p>
         Unless you ask us not to, we may contact you via email in the future to tell you about
         specials, new products or services, or changes to this privacy policy.
      </p>
      <h3>
         Your Access to and Control Over Information
      </h3>
      <p>
         You may opt out of any future contacts from us at any time. You can do the following at any
         time by contacting us via the email address provided on our website:
      </p>
      <ul>
         <li>
            See what data we have about you, if any.
         </li>
         <li>
            Change/correct any data we have about you.
         </li>
         <li>
            Have us delete any data we have about you.
         </li>
         <li>
            Express any concern you have about our use of your data
         </li>
      </ul>
      <h3>
         Registration
      </h3>
      <p>
         In order to use this website, a user must first complete the registration form. During
         registration a user is required to give certain information (such as name and email
         address). This information is used to contact you about the products/services on our site
         in which you have expressed interest. At your option, you may also provide demographic
         information (such as gender or age) about yourself, but it is not required.
      </p>
      <h3>
         Orders
      </h3>
      <p>
         We request information from you on our order form. To buy from us, you must provide contact
         information (like name and shipping address) and financial information (like credit card
         number, expiration date). This information is used for billing purposes and to fill your
         orders. If we have trouble processing an order, we'll use this information to contact you.
      </p>
      <h3>
         Sharing
      </h3>
      <p>
         We do not share any collected information with any third parties.
      </p>
      <h3>
         Security
      </h3>
      <p>
         We take precautions to protect your information. When you submit sensitive information via
         the website, your information is protected both online and offline.
      </p>
      <p>
         Wherever we collect sensitive information (such as credit card data), that information is
         encrypted and transmitted to us in a secure way. You can verify this by looking for a
         closed lock icon at the bottom of your web browser, or looking for "https" at the beginning
         of the address of the web page.
      </p>
      <p>
         While we use encryption to protect sensitive information transmitted online, we also
         protect your information offline. Only employees who need the information to perform a
         specific job (e.g. billing or customer service) are granted access to personally
         identifiable information. The computers/servers on which we store personally identifiable
         information are kept in a secure environment.
      </p>
      <h3>
         Cookies
      </h3>

      <p>
         We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard
         drive to help us improve your access to our site and identify repeat visitors to our site.
         For instance, when we use a cookie to identify you, you would not have to log in a password
         more than once, thereby saving time while on our site. Cookies can also enable us to track
         and target the interests of our users to enhance their experience on our site. Usage of a
         cookie is in no way linked to any personally identifiable information on our site.
      </p>
      <h3>
         Links
      </h3>
      <p>
         This web site may contain links to other sites. Please be aware that we are not responsible
         for the content or privacy practices of such other sites. We encourage our users to be
         aware when they leave our site and to read the privacy statements of any other site that
         collects personally identifiable information.
      </p>
      <h3>
         Surveys & Contests
      </h3>
      <p>
         From time-to-time our site requests information via surveys or contests. Participation in
         these surveys or contests is completely voluntary and you may choose whether or not to
         participate and therefore disclose this information. Information requested may include
         contact information (such as name and shipping address), and demographic information (such
         as zip code, age level). Contact information will be used to notify the winners and award
         prizes. Survey information will be used for purposes of monitoring or improving the use and
         satisfaction of this site.
      </p>
      <h3>
         Notification of Changes
      </h3>
      <p>
         Whenever material changes are made to the privacy notice we will notify you by email. You
         may unsubscribe from these notifications at any time.
      </p>
      <p>
         If you feel that we are not abiding by this privacy policy, you should contact us
         immediately via email at info@alignedapps.com
      </p>
   </mat-card-content>
</mat-card>
