<div class="header-container">
   <div class="container">
      <div class="header-content">
         <div class="logo-wrapper">
            <a routerLink="/" class="logo-text">
               <img src="assets/images/logos/tricolor-horizontal.svg" class="logo" />
            </a>
         </div>
         <div class="links">
            <ul>
               <!-- <li>
                  <a routerLink="/blog">Blog</a>
               </li> -->
               <li>
                  <a routerLink="/pricing"> Pricing</a>
               </li>
               <li>
                  <a routerLink="/get-started"> Request a Demo</a>
               </li>
               <li>
                  <a href="https://app.trellistools.com"> Sign In</a>
               </li>
            </ul>
         </div>
         <div class="sidenav">
            <button mat-icon-button (click)="toggleSidenav()">
               <mat-icon>menu</mat-icon>
            </button>
         </div>
      </div>
   </div>
</div>
