<div class="translucent-background">
   <div class="pricing-header header-text">
      Clarity, confidence, and trust - for everyone in your organization.
   </div>
   <div class="billing-period">
      <div class="billing-toggle">
         <span class="annual" (click)="showAnnual = false">Monthly Plan</span>
         <mat-slide-toggle color="primary" [(ngModel)]="showAnnual">Annual Plan</mat-slide-toggle>
      </div>
   </div>
   <div class="pricing-plans">
      <mat-card *ngFor="let plan of plans">
         <mat-card-title>{{ plan.name }}</mat-card-title>
         <mat-card-content>
            <div class="features">
               <div class="feature feature-desc">
                  {{ plan.description }}
               </div>
               <ul>
                  <li class="feature-desc" *ngFor="let feature of plan.features">{{ feature }}</li>
               </ul>
            </div>
            <div class="price price-full">${{ showAnnual ? plan.annual : plan.monthly }}/User</div>
            <div class="price">
               ${{ showAnnual ? plan.annualDisc : plan.monthlyDisc }}/User
               <br />
               <span class="note"> Early access rate</span>
            </div>
            <div class="button-container">
               <div class="button-wrapper">
                  <button mat-raised-button color="primary" [routerLink]="'/get-started'">
                     Get Started
                  </button>
               </div>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
   <div class="pricing-desc">
      Sustainable growth doesn't happen overnight. That's why we offer a 60 day money-back
      guarantee. If at any point during your first 60 days you are not satisfied with Trellis Tools,
      you may cancel and recieve a full refund.
   </div>
</div>
<div class="faqs translucent-background" *ngIf="showFaqs">
   <div class="header-text faq-header">Frequently Asked Questions</div>
   <mat-accordion>
      <mat-expansion-panel *ngFor="let faq of faqs">
         <mat-expansion-panel-header>
            <mat-panel-title>{{ faq.title }}</mat-panel-title>
         </mat-expansion-panel-header>
         <div class="answer">{{ faq.answer }}</div>
      </mat-expansion-panel>
   </mat-accordion>
</div>
