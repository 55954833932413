import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var Email: any;

@Component({
   selector: 'app-get-started',
   templateUrl: './get-started.component.html',
   styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
   form: FormGroup;
   submitted = false;
   sendingEmail = false;

   constructor() {}

   ngOnInit() {
      this.form = new FormGroup({
         firstName: new FormControl(),
         lastName: new FormControl(),
         email: new FormControl(null, [Validators.email, Validators.required]),
         company: new FormControl(),
         message: new FormControl()
      });
   }

   submit() {
      this.sendingEmail = true;
      const data = this.form.value;
      const message = data.message ? data.message.replace(/\n/g, '<br>') : '';
      Email.send({
         SecureToken: 'ce6250d2-8e99-4bd2-a56b-8c2379ee82b0',
         To: 'info@alignedapps.com',
         From: 'no-reply@alignedapps.com',
         Subject: 'Trellis Tools Email Form',
         Body: `New inquiry from ${data.firstName} ${data.lastName || ''}<br>
         Email: ${data.email}<br>
        Company: ${data.company || ''}<br>
        Message: ${message}<br>
      `
      })
         .then((result) => {
            console.log(result);
            this.submitted = true;
            this.sendingEmail = false;
         })
         .catch((error) => {
            this.sendingEmail = false;
         });
   }
}
