<div class="content">
   <app-header class="header" (sidenavToggled)="toggleSidenav()"></app-header>
   <mat-sidenav-container>
      <mat-sidenav-content>
         <div class="background"></div>
         <div class="main-content container">
            <router-outlet></router-outlet>
         </div>
      </mat-sidenav-content>
      <mat-sidenav [opened]="sidenavOpen" mode="over" position="end">
         <mat-nav-list>
            <!-- <a mat-list-item (click)="sidenavOpen = false" routerLink="/blog">Blog</a> -->
            <a mat-list-item (click)="sidenavOpen = false" href="https://app.opsmanager.io"
               >Sign In</a
            >
            <a mat-list-item (click)="sidenavOpen = false" routerLink="/pricing">Pricing</a>
            <a mat-list-item (click)="sidenavOpen = false" routerLink="get-started">Get a Demo</a>
         </mat-nav-list>
      </mat-sidenav>
   </mat-sidenav-container>

   <app-footer></app-footer>
</div>
