import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-pricing',
   templateUrl: './pricing.component.html',
   styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
   showAnnual = false;
   showFaqs = false;

   plans = [
      // {
      //    name: 'Trellis Process',
      //    description: 'Document your operations',
      //    features: [
      //       'Org Builder',
      //       'Team Management',
      //       'Dashboards',
      //       'Task Documentation',
      //       'Task Assignments',
      //       'Role Descriptions',
      //       'Report Generation'
      //    ],
      //    monthly: 15,
      //    annual: 150,
      //    monthlyDisc: 10,
      //    annualDisc: 100
      // },
      // {
      //    name: 'Trellis Evolve',
      //    description: 'Create a strategic plan',
      //    features: [
      //       'Org Builder',
      //       'Team Management',
      //       'Dashboards',
      //       'Goal Tracking',
      //       'Rock and Issue Management',
      //       'Meeting Notes',
      //       'Performance Benchmarks'
      //    ],
      //    monthly: 15,
      //    annual: 150,
      //    monthlyDisc: 10,
      //    annualDisc: 100
      // },
      {
         name: 'Complete',
         description: 'All tools at one low price',
         features: [
            'Org Builder',
            'Team Management',
            'Custom Dashboards',
            'Task Documentation',
            'Task Assignments',
            'Role Descriptions',
            'Report Generation',
            'Goal Tracking',
            'Rock and Issue Management',
            'Meeting Notes',
            'Performance Benchmarks',
         ],
         monthly: 25,
         annual: 250,
         monthlyDisc: 15,
         annualDisc: 150,
      },
      {
         name: 'Reference',
         description: '',
         features: ['Custom Dashboards', 'Read-only access to all other features'],
         monthly: 10,
         annual: 100,
         monthlyDisc: 5,
         annualDisc: 50,
      },
   ];

   faqs = [
      {
         title: 'Can I customize which apps different users in my organization have access to?',
         answer: `Yes! You can set the access level for each user on an app-by-app basis.
         Each user's monthly charge is based on the apps they have access to - if they don't have access, you don't get charged.`,
      },
      {
         title: 'What if I want to cancel my subscription?',
         answer: `You may cancel your subscription at any time from within the app.
         If you cancel within the 60 day money-back guarantee period, you will recieve a full refund.
         Otherwise, you will receive a prorated refund based on the time remaining in your billing cycle.`,
      },
   ];

   constructor() {}

   ngOnInit() {}
}
