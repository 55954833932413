import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { PricingComponent } from './pricing/pricing.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularMaterialModules } from './angular-material';
import { FeaturesComponent } from './features/features.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
   declarations: [
      AppComponent,
      LandingComponent,
      GetStartedComponent,
      PricingComponent,
      HeaderComponent,
      FooterComponent,
      FeaturesComponent,
      TermsOfServiceComponent,
      PrivacyPolicyComponent,
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      AngularMaterialModules,
      FormsModule,
      MatCarouselModule,
      ReactiveFormsModule,
      ScullyLibModule,
   ],
   providers: [],
   bootstrap: [AppComponent],
})
export class AppModule {}
