<div class="container footer">
   <mat-icon class="copyright">copyright</mat-icon>
   <div class="copyright-text">
      2019 Aligned Applications |
      <a href="mailto:info@alignedapps.com" target="_blank">
         info@alignedapps.com
      </a>
      | <a href="/terms-of-service">Terms Of Service</a> |
      <a href="/privacy-policy">Privacy Policy</a>
   </div>
</div>
