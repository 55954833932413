<div class="translucent-background">
   <div class="header-text">
      Align
   </div>
   <div class="description-text">
      An aligned organization is an organization in which everyone is working towards the same
      purposes. In an aligned organization, every person in the organization understands not only
      what the organization’s mission and goals are, but also how their work helps the organization
      achieve its goals and mission.
   </div>
   <mat-carousel
      timings="500ms ease-in"
      [autoplay]="false"
      color="primary"
      maxWidth="auto"
      [maintainAspectRatio]="false"
      [slideHeight]="'500px'"
      proportion="25"
      slides="5"
      [loop]="false"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      orientation="ltr"
   >
      <mat-carousel-slide [image]="'assets/images/screenshots/overview.png'" [hideOverlay]="true">
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Vision
               </div>
               <div class="slide-description">
                  Ensure that every employee has clarity and understanding of the purpose and vision
                  of your organization. The Vision tool provides guiding questions to help you
                  define and communicate the high level purpose, vision, and principles that drive
                  your organization.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
      <mat-carousel-slide [image]="'assets/images/screenshots/orgbuilder.png'" [hideOverlay]="true">
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Org Builder
               </div>
               <div class="slide-description">
                  Create a functional org chart to provide a clear summary of all the work being
                  done in your organization, and how each area contributes to your organization's
                  success. The Org Builder allows you to organize and view of all the work that your
                  organization does, from business units down to individual tasks.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
   </mat-carousel>
</div>

<div class="translucent-background">
   <div class="header-text">
      Execute
   </div>
   <div class="description-text">
      Having an organization where everyone is aligned towards the same purpose is only half the
      battle. Each member of the organization also must execute on the work to be done. Structured
      operations ensures that each member of the organization understands the steps entailed in
      their assigned work, the desired output of their work, and how that output is measured.
   </div>
   <mat-carousel
      timings="500ms ease-in"
      [autoplay]="false"
      color="primary"
      maxWidth="auto"
      [maintainAspectRatio]="false"
      [slideHeight]="'500px'"
      proportion="25"
      slides="5"
      [loop]="false"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      orientation="ltr"
   >
      <mat-carousel-slide
         [image]="'assets/images/screenshots/documentation.png'"
         [hideOverlay]="true"
      >
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Documentation
               </div>
               <div class="slide-description">
                  All your SOP documentation in one place. Easy to build, easy to find later.
                  Generate pdf summaries and share with your team.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
      <mat-carousel-slide
         [image]="'assets/images/screenshots/roleDescription.png'"
         [hideOverlay]="true"
      >
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Role Descriptions
               </div>
               <div class="slide-description">
                  Assign work and create role descrptions, so that every employee has a clear
                  summary of their role with all their assigned tasks.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
      <mat-carousel-slide
         [image]="'assets/images/screenshots/performance.png'"
         [hideOverlay]="true"
      >
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Benchmarks
               </div>
               <div class="slide-description">
                  Identify and track benchmarks and key results to assure the quality of the work
                  being done and identify each department's priorities for improvement and change.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
   </mat-carousel>
</div>

<div class="translucent-background">
   <div class="header-text">
      Evolve
   </div>
   <div class="description-text">
      To achieve sustainable growth an organization must be able to adapt and respond to a changing
      business environment. Change is an inherent part of growth – planning for growth is the same
      as planning for change.
   </div>
   <mat-carousel
      timings="500ms ease-in"
      [autoplay]="false"
      color="primary"
      maxWidth="auto"
      [maintainAspectRatio]="false"
      [slideHeight]="'500px'"
      proportion="25"
      slides="5"
      [loop]="false"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      orientation="ltr"
   >
      <mat-carousel-slide [image]="'assets/images/screenshots/goals.png'" [hideOverlay]="true">
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Goals
               </div>
               <div class="slide-description">
                  Set a long term vision for your organization and track your progress.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
      <mat-carousel-slide [image]="'assets/images/screenshots/rocks.png'" [hideOverlay]="true">
         <div class="slide-caption-container">
            <div class="slide-caption">
               <div class="slide-header">
                  Rocks
               </div>
               <div class="slide-description">
                  Bridge the gap between where you are and where you need to be. Rocks are targeted,
                  90 day initiatives that focus departments towards improving in a specific,
                  measurable manner.
               </div>
            </div>
         </div>
      </mat-carousel-slide>
   </mat-carousel>
</div>

<div class="header-text text-center">
   Ready to get started?
</div>
<div class="button-container">
   <div class="button-wrapper">
      <button mat-raised-button color="primary" routerLink="/get-started">Request a Demo</button>
   </div>
</div>
