<div class="get-started-container translucent-background">
   <div class="header-text">
      Take the first step towards the next phase of your business.
   </div>
   <div class="get-started-desc">
      Ready to get started, or just interested in hearing more? Sign up below and we'll get in
      touch.
   </div>

   <mat-card>
      <mat-card-content>
         <ng-container *ngIf="submitted; else contactForm">
            <div class="image-wrapper">
               <img class="mail-box" src="/assets/images/drawings/mail-box.svg" />
            </div>
            <div class="thank-you">
               Thank you for reaching out! We will contact you shortly.
            </div>
         </ng-container>
         <ng-template #contactForm>
            <form [formGroup]="form">
               <mat-form-field>
                  <input
                     matInput
                     type="email"
                     formControlName="email"
                     placeholder="Email"
                     required
                  />
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     type="text"
                     formControlName="firstName"
                     placeholder="First Name"
                     required
                  />
               </mat-form-field>
               <mat-form-field>
                  <input matInput type="text" formControlName="lastName" placeholder="Last Name" />
               </mat-form-field>
               <mat-form-field>
                  <input matInput type="text" formControlName="company" placeholder="Company" />
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message"></textarea>
               </mat-form-field>
            </form>
         </ng-template>
      </mat-card-content>
      <mat-card-actions *ngIf="!submitted">
         <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="submit()">
            Submit
         </button>
      </mat-card-actions>
      <mat-spinner *ngIf="sendingEmail" color="primary"></mat-spinner>
   </mat-card>
</div>
