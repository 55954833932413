import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Component({
   selector: 'app-features',
   templateUrl: './features.component.html',
   styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, AfterViewInit {
   features = [
      {
         title: 'Why',
         text: `Ensure that every employee has clarity and understanding of the purpose and vision of your
      organization. The Org Overview provides guiding questions to help you define and communicate
      the high level purpose, vision, and principles that drive your organization.`,
         image: 'assets/images/screenshots/overview.png'
      },
      {
         title: 'What',
         text: `Create a functional org chart to provide a clear summary of all the work being done in your
      organization, and how each area contributes to your organization's success. The Org Builder
      allows you to organize and view of all the work that your organization does, from business
      units down to individual tasks.`,
         image: 'assets/images/screenshots/orgbuilder.png'
      },
      {
         title: 'Who',
         text: `Assign work and create role descrptions, so that every employee has a clear summary of their
      role with all their assigned tasks.`,
         image: 'assets/images/screenshots/roleDescription.png'
      },
      {
         title: 'How',
         text: `All your SOP documentation in one place. Easy to build, easy to find later. Generate pdf
      summaries and share with your team.`,
         image: 'assets/images/screenshots/documentation.png'
      },
      {
         title: 'Performance',
         text: `Track metrics and identify each department's priorities for improvement and change.`,
         image: 'assets/images/screenshots/performance.png'
      },
      {
         title: 'Goals',
         text: `Set a long term vision for your organization and track your progress.`,
         image: 'assets/images/screenshots/goals.png'
      },
      {
         title: 'Rocks',
         text: `Bridge the gap between where you are and where you need to be.`,
         image: 'assets/images/screenshots/rocks.png'
      }
   ];

   constructor(private changeDetector: ChangeDetectorRef) {}

   ngOnInit() {}

   ngAfterViewInit() {
      this.changeDetector.detectChanges();
   }
}
