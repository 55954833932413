<mat-card>
   <mat-card-title>Terms of Service</mat-card-title>
   <mat-card-content>
      Last revised on 25 November 2019

      <h3>The Gist</h3>
      <p>
         Aligned Applications operates the Trellis Tools service, which we hope you use. If you use
         it, please use it responsibly. If you don't, we'll have to terminate your account.
      </p>
      <p>
         For paid accounts, you'll be charged on a monthly basis. You can cancel anytime, but You
         will only receive a refund for the remaining time in your billing period.
      </p>
      <p>
         You own the Content that you provide to Trellis Tools and you're responsible for keeping it
         safe.
      </p>
      <p>
         The Terms of Service, Trellis Tools itself, and our prices can change at any time. We'll
         warn you 30 days in advance of any price changes. We'll try to warn you about major changes
         to the Terms of Service or Trellis Tools, but we make no guarantees.
      </p>
      <p>
         That's the basic idea, but You must read through the entire Terms of Service below and
         agree with all the details before You use any of our sites (whether or not you have created
         an account).
      </p>
      <h3>
         Reuse
      </h3>
      <p>
         This document is an adaptation of the Heroku Terms of Service, which is turn an adaptation
         of the Google App Engine Terms of Service. The original work has been modified with
         permission under the Creative Commons Attribution 3.0 License. Neither Heroku, Inc. nor
         Google, Inc. is not connected with and does not sponsor or endorse Aligned Applications or
         its use of the work.
      </p>
      <p>
         You're welcome to adapt and use this document for your own needs. If you make an
         improvement, we'd appreciate it if you would let us know so we can consider improving our
         own document.
      </p>
      <h3>
         Your Agreement with Aligned Applications
      </h3>
      <p>
         Your use of the Aligned Applications service is governed by this agreement (the "Terms").
         "Aligned Applications" means Aligned Applications Inc. The "Service" means the services
         Aligned Applications makes available include our web sites (trellistools.com,
         app.trellistools.com, opsmanager.io and app.opsmanager.io ), our blog, our API, and any
         other software, sites, and services offered by Aligned Applications in connection to any of
         those. "Customer Content" means any Content you submit to Aligned Applications for the
         purpose of using the Service. "Content" means all content generated by Aligned Applications
         on your behalf (including metric data) and does not include Customer Content.
      </p>
      <p>
         In order to use the Service, You (the "Customer", "You", or "Your") must first agree to the
         Terms. You understand and agree that Aligned Applications will treat Your use of the
         Service as acceptance of the Terms from that point onwards.
      </p>
      <p>
         Aligned Applications may make changes to the Terms from time to time. You may reject the
         changes by terminating Your account. You understand and agree that if You use the Service
         after the date on which the Terms have changed, Aligned Applications will treat Your use as
         acceptance of the updated Terms.
      </p>
      <p>
         If you have any question about the Terms, please contact us at info@alignedapps.com.
      </p>
      <h3>
         Your Account
      </h3>
      <ul>
         <li>
            You may not use the Service if You are a person barred from receiving the Service under
            the laws of the United States or other countries, including the country in which You are
            resident or from which You use the Service.
         </li>
         <li>
            You may not use the service unless you are over the age of 13.
         </li>
         <li>
            You must be a human. Account created by automated methods are not permitted.
         </li>
      </ul>
      <h3>
         Use of the Service
      </h3>
      <ul>
         <li>
            You must provide accurate and complete registration information any time You register to
            use the Service.
         </li>
         <li>
            You are responsible for the security of Your passwords and for any use of Your account.
         </li>
         <li>
            Your use of the Service must comply with all applicable laws, regulations and
            ordinances.
         </li>
         <li>
            You agree to not engage in any activity that interferes with or disrupts the Service.
         </li>
         <li>
            Aligned Applications reserves the right to enforce quotas and usage limits (to any
            resources, including the API) at its sole discretion, with or without notice, which may
            result in Aligned Applications disabling or throttling your usage of the Service for any
            amount of time.
         </li>
         <li>
            You may not allow multiple people to use the same account or otherwise access the
            Service in a manner intended to avoid incurring fees.
         </li>
      </ul>
      <h3>
         Service Policies and Privacy
      </h3>
      <p>
         The Service shall be subject to the privacy policy for the Service available at
         https://trellistools.com/privacy-policy. You agree to the use of Your data in accordance
         with Aligned Applications's privacy policies.
      </p>
      <h3>
         Fees for Use of the Service
      </h3>
      <ul>
         <li>
            The Service may be provided to You without charge up with certain limits or for a
            certain "trial" period of time.
         </li>
         <li>
            Usage over this limit (or after the "trial" period) or requires Your purchase of
            additional resources or services.
         </li>
         <li>
            For all purchased resources and services, we will charge Your credit card on a monthly
            or annual basis.
         </li>
         <li>
            Payments are non-refundable. There will be no refunds or credits for partial months of
            service, upgrade/downgrade refunds, or refunds for months unused with an open account.
         </li>
         <li>
            Charges are solely based on Aligned Applications's measurements of Your use of the
            Service, unless otherwise agreed to in writing.
         </li>
         <li>
            All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities,
            and You shall be responsible for payment of all such taxes, levies, or duties.
         </li>
         <li>
            You acknowledge and agree that any credit card and related billing and payment
            information that You provide to Aligned Applications may be shared by Aligned
            Applications with companies who work on Aligned Applications's behalf, such as payment
            processors and/or credit agencies, solely for the purposes of checking credit, effecting
            payment to Aligned Applications and servicing Your account.
         </li>
         <li>
            Aligned Applications may change its fees and payment policies for the Service by
            notifying You at least thirty (30) days before the beginning of the billing cycle in
            which such change will take effect.
         </li>
      </ul>
      <h3>
         Cancellation and Termination
      </h3>
      <ul>
         <li>
            You must cancel Your account from within the Trellis Tools application. An email or
            phone request to cancel Your account is not considered cancellation.
         </li>
         <li>
            You will only receive a refund for prepaid services. You will recieve a prorated refund
            based on the remaining time in Your billing period.
         </li>
         <li>
            If You cancel the Service before the end of Your current paid up month, Your
            cancellation will take effect immediately and You will not be charged again.
         </li>
         <li>
            All of Customer Content and Content will, within a reasonable amount of time to be
            determined solely by Aligned Applications, be deleted from the Service upon
            cancellation.
         </li>
         <li>
            You agree that Aligned Applications, in its sole discretion and for any or no reason,
            may terminate or suspend Your account. You agree that any termination of Your access to
            the Service may be without prior notice, and You agree that Aligned Applications will
            not be liable to You or any third party for such termination.
         </li>
      </ul>
      <h3>
         Customer Content
      </h3>
      <li>
         Aligned Applications claims no ownership or control over any Customer Content. You retain
         copyright and any other rights You already hold in the Customer Content and You are
         responsible for protecting those rights, as appropriate.
      </li>
      <li>
         You agree to assume full responsibility for configuring the Service to allow appropriate
         access to any Customer Content provided to the Service.
      </li>
      <li>
         You understand that your projects will display Customer Content to You and any
         collaborators that you designate for that project.
      </li>
      <li>
         You retain sole responsibility for any collaborators or third-party services that you allow
         to view Customer Content and entrust them at your own risk.
      </li>
      <li>
         Aligned Applications is not responsible if you fail to configure, or misconfigure, your
         project and inadvertently allow unauthorized parties view any Customer Content.
      </li>
      <h3>
         Ideas and Feedback
      </h3>
      <p>
         You may choose to or we may invite You to submit comments or ideas about the Service,
         including but not limited to ideas about improving the Service or our products ("Ideas").
         By submitting any Idea, You agree that Your disclosure is unsolicited and without
         restriction and will not place Aligned Applications under any fiduciary or other
         obligation, and that we are free to use the Idea without any additional compensation to
         You, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone.
      </p>
      <h3>
         Modification of the Service
      </h3>
      <ul>
         <li>
            You acknowledge and agree that the Service may change from time to time without prior
            notice to You.
         </li>
         <li>
            Changes include, without limitation, changes to fee and payment policies, security
            patches, added or removed functionality, and other enhancements or restrictions.
         </li>
         <li>
            Aligned Applications shall not be liable to you or to any third party for any
            modification, price change, suspension or discontinuance of the Service.
         </li>
      </ul>
      <h3>
         External Resources
      </h3>
      <p>
         The Services may include hyperlinks to other web sites or content or resources or email
         content. You acknowledge and agree that Aligned Applications is not responsible for the
         availability of any such external sites or resources, and does not endorse any advertising,
         products or other materials on or available from such web sites or resources.
      </p>
      <h3>
         License from Aligned Applications and Restrictions
      </h3>
      <p>
         Aligned Applications gives You a personal, worldwide, royalty-free, non-assignable and
         non-exclusive license to use the software provided to You by Aligned Applications as part
         of the Service as provided to You by Aligned Applications. This license is for the sole
         purpose of enabling You to use and enjoy the benefit of the Service as provided by Aligned
         Applications, in the manner permitted by the Terms.
      </p>
      <p>
         You may not (and You may not permit anyone else to): (a) copy, modify, create a derivative
         work of, reverse engineer, decompile or otherwise attempt to extract the Content of the
         Service or any part thereof, unless this is expressly permitted or required by law, or
         unless You have been specifically told that You may do so by Aligned Applications, in
         writing (e.g., through an open source software license); or (b) attempt to disable or
         circumvent any security mechanisms used by the Service.
      </p>
      <p>
         Open source software licenses for components of the Service released under an open source
         license constitute separate written agreements. To the limited extent that the open source
         software licenses expressly supersede these Terms, the open source licenses govern Your
         agreement with Aligned Applications for the use of the components of the Service released
         under an open source license.
      </p>
      <h3>
         Exclusion of warranties
      </h3>
      <ul>
         <li>
            You expressly understand and agree that your use of the service is at your sole risk and
            that the service is provided "as is" and "as available.".
         </li>
         <li>
            You agree that Aligned Applications has no responsibility or liability for the deletion
            or failure to store any Content and other communications maintained or transmitted
            through use of the Service. You further acknowledge that You are solely responsible for
            securing and backing up Customer Content.
         </li>
         <li>
            Aligned Applications does not warrant to you that: (a) your use of the service will meet
            your requirements, (b) your use of the service will be uninterrupted, timely, secure or
            free from error, (c) the results or data provided by the Service will be accurate, (d)
            the quality of the service will meet your expectations and (e) any errors in the Service
            will be fixed.
         </li>
      </ul>
      <h3>
         Limitation of liability
      </h3>
      <p>
         You expressly understand and agree that Aligned Applications, its subsidiaries and
         affiliates, and its licensors shall not be liable to you for any direct, indirect,
         incidental, special consequential or exemplary damages which may be incurred by you,
         however caused and under any theory of liability. This shall include, but not be limited
         to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or
         business reputation, any loss of data suffered, cost of procurement of substitute goods or
         services, or other intangible loss (whether or not Aligned Applications has been advised of
         or should have been aware of the possibility of any such losses arising).
      </p>
      <h3>
         Indemnification
      </h3>
      <p>
         You agree to hold harmless and indemnify Aligned Applications, and its subsidiaries,
         affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners
         (collectively "Aligned Applications and Partners") from and against any third party claim
         arising from or in any way related to (a) Your breach of the Terms, (b) Your use of the
         Service, (c) Your violation of applicable laws, rules or regulations in connection with the
         Service, or (d) Your Customer Content, including any liability or expense arising from all
         claims, losses, damages (actual and consequential), suits, judgments, litigation costs and
         attorneys' fees, of every kind and nature. In such a case, Aligned Applications will
         provide You with written notice of such claim, suit or action.
      </p>
      <h3>
         General Legal Terms
      </h3>
      <p>
         The Terms constitute the whole legal agreement between You and Aligned Applications and
         govern Your use of the Service and completely replace any prior agreements between You and
         Aligned Applications in relation to the Service.
      </p>
      <p>
         You agree that if Aligned Applications does not exercise or enforce any legal right or
         remedy which is contained in the Terms (or which Aligned Applications has the benefit of
         under any applicable law), this will not be taken to be a formal waiver of Aligned
         Applications's rights and that those rights or remedies will still be available to Aligned
         Applications.
      </p>
      <p>
         Aligned Applications shall not be liable for failing or delaying performance of its
         obligations resulting from any condition beyond its reasonable control, including but not
         limited to, governmental action, acts of terrorism, earthquake, fire, flood or other acts
         of God, labor conditions, power failures, and Internet disturbances.
      </p>
   </mat-card-content>
</mat-card>
