<div class="landing">
   <div class="landing-inner translucent-background">
      <div class="landing-inner-flex">
         <div class="landing-text">
            <div class="landing-header">
               Leverage the power of <span class="header-emphasis">structured operations</span
               ><br />
               for sustainable growth.
            </div>

            <div class="landing-description landing-description-desktop">
               Trellis Tools provides a framework for structured operations, enabling you to take
               control of your business operations and develop a strategy for sustainable,
               repeatable growth.
               <div class="button-container">
                  <div class="button-wrapper">
                     <button mat-raised-button color="primary" routerLink="/get-started">
                        Get Started
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="landing-image-container">
            <img class="landing-image" src="assets/images/drawings/in-progress.svg" />
         </div>
      </div>
      <div class="landing-description landing-description-tablet">
         <div class="landing-description-tablet-text">
            Trellis Tools provides a framework for structured operations, enabling you to take
            control of your day to day operations and develop a strategy for sustainable, repeatable
            growth.
         </div>
         <div class="button-container">
            <div class="button-wrapper">
               <button mat-raised-button color="primary" routerLink="/get-started">
                  Get Started
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="landing">
   <div class="landing-inner translucent-background">
      <div class="landing-inner-flex">
         <div class="landing-image-container">
            <img class="landing-image" src="assets/images/drawings/data-points.svg" />
         </div>
         <div class="landing-text">
            <div class="landing-header">
               Align. Execute. Evolve.
            </div>

            <div class="landing-description landing-description-desktop">
               Trellis Tools provides the tools needed to implement each phase of structured
               operations:
               <ul>
                  <li><b>Align</b> the organization around a shared purpose and mission,</li>
                  <li><b>Execute</b> the core work of the organization, and</li>
                  <li><b>Evolve</b> into an organization that operates at its full potential</li>
               </ul>
            </div>
         </div>
      </div>
      <div class="landing-description landing-description-tablet">
         <div class="landing-description-tablet-text">
            Trellis Tools provides the tools needed to implement each phase of structured
            operations:
            <ul>
               <li><b>Align</b> the organization around a shared purpose and mission,</li>
               <li><b>Execute</b> the core work of the organization, and</li>
               <li><b>Evolve</b> into an organization that operates at its full potential</li>
            </ul>
         </div>
      </div>
   </div>
</div>

<app-features></app-features>
